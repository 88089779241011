<template>
  <div id="app">
    <div class="navbar" :class="{ 'affix': isAffix, 'affix-top': isAffixTop }" >
      <a :class="{ 'active': activeSection === 'top' }" @click="scrollToTop" class="name">ZHIHAO HUANG</a>
      <div class="navbar-right">
        <!-- 显示下拉菜单按钮 -->
      <div class="dropdown" v-if="screenWidth < 570">
        <button class="dropbtn"><font-awesome-icon :icon="['fas', 'bars']" /></button>
        <div class="dropdown-content">
          <a :class="{ 'active': activeSection === 'home' }" @click="scrollToHome">Home</a>
          <a :class="{ 'active': activeSection === 'about' }" @click="scrollToAbout">About</a>
          <a :class="{ 'active': activeSection === 'contact' }" @click="scrollToContact">Contact</a>
        </div>
      </div>
      <!-- 直接显示链接 -->
      <div v-else >
        <a :class="{ 'active': activeSection === 'home' }" @click="scrollToHome">Home</a>
        <a :class="{ 'active': activeSection === 'about' }" @click="scrollToAbout">About</a>
        <a :class="{ 'active': activeSection === 'contact' }" @click="scrollToContact">Contact</a>
      </div>
      </div>
    </div>
    <div class="decoration">
      <div class="avatar" style="margin-left: -50px;"></div>
      <div class="avatar" style="margin-top: -20px; margin-right: -10px;"></div>
    </div>
    <div class="body">
      <Home ref="home" title="Welcome to My Homepage" content="This is my personal homepage where you can learn more about me." />
      <About ref="about" title="About Me" content="I am a passionate developer who loves creating web applications using Vue.js and other modern technologies." />
      <Contact ref="contact" title="Contact Me" content="If you'd like to get in touch with me, feel free to send me an email at my.email@example.com." />
      <Copyright />
      
    </div>
  </div>
</template>

<script>
import Home from './views/Home.vue';
import About from './views/About.vue';
import Contact from './views/Contact.vue';
import Card from './components/Card.vue';
import Copyright from './components/Copyright.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import FPS from './components/FPS.vue';

// 添加 Solid 图标到图标库
library.add(fas);

export default {
  name: 'App',
  components: {
    Home,About,Contact,FontAwesomeIcon,Card,Copyright,FPS
  },
  data (){
    return{
      isAffix: false,
      isAffixTop: true,
      isCardVisible: false,
      screenWidth: window.innerWidth,
      activeSection: 'top', // 当前所在区域
     
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    // 在组件挂载后添加滚动事件监听
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToHome() {
      this.$refs.home.scrollTo();
    },
    scrollToAbout() {
      this.$refs.about.scrollTo();
    },
    scrollToContact() {
      this.$refs.contact.scrollTo();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动效果
      });
    },
    handleScroll() {
      const scrollPosition = window.scrollY;
      // 根据滚动位置切换状态
      this.isAffix = scrollPosition >= 100; // 例如滚动超过 100 像素触发 affix 状态
      this.isAffixTop = scrollPosition < 100; // 滚动回到 100 像素之上触发 affix-top 状态

      const homeSectionRect = this.$refs.home.$el.getBoundingClientRect();
      const aboutSectionRect = this.$refs.about.$el.getBoundingClientRect();
      const contactSectionRect = this.$refs.contact.$el.getBoundingClientRect();

      const scrollTop = window.scrollY;

      const threshold = 0; // 适当的阈值

      if (scrollTop >= homeSectionRect.top - threshold && scrollTop < aboutSectionRect.top - threshold) {
        this.activeSection = 'home';
      } else if (scrollTop >= aboutSectionRect.top - threshold && scrollTop < contactSectionRect.top + 150) {
        this.activeSection = 'about';
      } else if (scrollTop >= contactSectionRect.top + 150) {
        this.activeSection = 'contact';
      } else {
        this.activeSection = 'top';
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    showCard() {
      // 设置卡片内容数据
      this.currentCard.imageUrl = "@/assets/QRcode.jpeg"; // 设置图片路径
      this.currentCard.title = "Card Title";
      this.currentCard.description = "Card Description";

      this.isCardVisible = true;
    },
    hideCard() {
      this.isCardVisible = false;
    }
  },
    
};
</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
}

.navbar {
  justify-content: space-between;
  display: flex;
  background-color: #333333;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: padding .3s;
  border-radius: 5px;
}

.name{
  font-size: x-large;
  font-family: 'Oswald', sans-serif !important
}

.affix {
  /* affix 状态样式 */
  position: fixed;
  top: 0;
  /* ... */
}
.affix-top {
  /* affix-top 状态样式 */
  /* ... */
  padding: 20px 0;
  padding-left: 50px;
}

.navbar a {
  color: white;
  margin: 0 10px;
  margin-right: 20px;
  cursor: pointer; /* 添加这行 */
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

.navbar a.active {
  color: #0088a9; /* 设置字体颜色为蓝色 */
}

.navbar-right{
  margin-top: 8px;
  margin-right: 120px;
}

/* 下拉菜单按钮样式 */
.dropbtn {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: larger;
  transition: font-size 0.3s; /* 添加字体大小的过渡效果 */
}

/* 下拉菜单内容样式 */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  border-radius: 10%;
  min-width: 160px;
  z-index: 1;
  border: 0.1px solid #ccc; /* 设置边框 */
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2); /* 添加阴影 */
}

/* 下拉菜单链接样式 */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* 鼠标悬停时改变背景颜色 */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* 显示下拉菜单 */
.dropdown:hover .dropdown-content {
  display: block;
}

.decoration{
  display: flex;
  justify-content: space-between;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%; /* 将元素变成圆形 */
  background-color: #2d48c2; /* 背景颜色，可以是图片、颜色值等 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 可以添加其他样式，如边框、阴影等 */
}


</style>
