<template>
  <div class="contact">
    <h2>Contact Me</h2>
    <p>If you want to contact me, please do so through the following methods.</p>
    <div class="icons">
      <div class="icon" @click="showCard('gmail')">
        <font-awesome-icon :icon="['fas', 'envelope']" />
      </div>
      <div class="icon" @click="showCard('qq')">
        <font-awesome-icon :icon="['fab', 'qq']" />
      </div>
    </div>
    <Card v-if="isCardVisible" :cardData="currentCard" @closeCard="hideCard" />
  </div>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { fas } from '@fortawesome/free-solid-svg-icons';
  import { fab } from '@fortawesome/free-brands-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import Card from '@/components/Card.vue'

  // 添加 Solid 图标到图标库
  library.add(fas);
  library.add(fab);

  export default {
    name: 'Contact',
    components: {
      FontAwesomeIcon,Card
    },
    data (){
      return{
        isCardVisible: false,
        currentCard: {
          imageUrl: require("@/assets/Gmail.jpeg"),
          title: "GMail",
          description: "kellehod@gmail.com"
        }

      }
    },
    methods: {
          scrollTo() {
          this.$scrollTo(this.$el, 500); // 滑动到当前组件
          },
          showCard(type) {
            this.isCardVisible = true;

            if (type === 'qq'){
              this.currentCard.title = 'QQ'
              this.currentCard.imageUrl = require("@/assets/QQcode.jpg")
              this.currentCard.description = "Please scan the QR code to add"
            }else{
              this.currentCard.title = 'Gmail'
              this.currentCard.imageUrl = require("@/assets/Gmail.jpeg")
              this.currentCard.description = "kellehod@gmail.com"
            }
          },
          hideCard() {
            this.isCardVisible = false;
          }
      },
  };
</script>

<style scoped>
.contact {
  text-align: center;
  padding: 35px;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin: 0 20px; /* 调整图标之间的间距 */
  font-size: 24px; /* 调整图标大小 */
  transition: transform 0.2s; /* 添加过渡效果 */
}
.icon:hover{
  transform: scale(1.5); /* 悬停时放大 */
}
</style>
