<template>
    <div>
      <div id="fps-display">Screen Refresh Rate: {{ screenRefreshRate }} Hz</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        screenRefreshRate: 0
      };
    },
    methods: {
      updateRefreshRate() {
        const startTime = performance.now();
        let frameCount = 0;
  
        const update = () => {
          frameCount++;
          if (performance.now() - startTime >= 200) {
            this.screenRefreshRate = frameCount;
          } else {
            requestAnimationFrame(update);
          }
        };
  
        requestAnimationFrame(update);
      }
    },
    mounted() {
      this.updateRefreshRate();
    }
  };
  </script>
  
  <style scoped>
  #fps-display {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
  }
  </style>
  