<template>
    <footer class="copyright">
      <p>&copy; {{ currentYear }} <a :href="link" target="_blank">{{ name }}</a>. All rights reserved.</p>
    </footer>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentYear: new Date().getFullYear(),
        link: "https://github.com/kellehod",
        name: "kellhod",
      };
    }
  };
  </script>
  
  <style scoped>
  /* 添加你的样式 */
  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
  .copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  .copyright p{
    margin: 0; /* 去除默认的上下 margin */
    text-align: center; /* 水平居中文本 */
    font-family: 'Poppins', sans-serif;
    color: rgba(0, 0, 0, 0.8);
    font-size: smaller;
  }

  .copyright a{
    color: #007bff;
    text-decoration: none;
  }
  </style>
  