<template>
  <div class="home">
    <div class="profile-container" :class="{ 'vertical-layout': aspectRatio < 1.2 }">
    <div class="profile-left">
        <img :src="headImageUrl" alt="Avatar">
        <div class="portrait-title">
            <h2 itemprop="name">{{ name }}</h2>
            <h3 itemprop="jobTitle">{{ jobTitle }}</h3>
            <h3 itemprop="worksFor" itemscope="" itemtype="http://schema.org/Organization">
            <span itemprop="name">{{ worksFor }}</span>
            </h3>
        </div>
        <ul class="network-icon">
            <li><a :href="githubLink" target="_blank"><font-awesome-icon :icon="['fab', 'github']" />
            </a></li>    
            <li><a :href="blogLink" target="_blank"><font-awesome-icon :icon="['fas', 'blog']" />
            </a></li>
            <li><a :href="bilibiliLink" target="_blank"><font-awesome-icon :icon="['fab', 'bilibili']" />
            </a></li>
        </ul>
    </div>
    <div class="profile-right" :class="{ 'margin-left': aspectRatio > 1.2 }">
      <!-- 第二个 div 的内容 -->
      <h2>Personal Profile</h2>
      <p>{{ PersonalProfile }}</p>

      <div class="education">
        <h3>Education</h3>
        <ul class="ul-edu"> 
        <li v-for="(edu, index) in educationList" :key="index">
            <div class="graduation-cap">
            <font-awesome-icon :icon="['fas', 'graduation-cap']" />
            </div>
            <div class="description">
            <p class="course">{{ edu.course }}</p>
            <p class="institution">{{ edu.institution }}</p>
            </div>
        </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { fas } from '@fortawesome/free-solid-svg-icons';
    import { fab } from '@fortawesome/free-brands-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  // 添加 Solid 图标到图标库
  library.add(fas);
  library.add(fab);

    export default {
    name: 'Home',
    components:{
        FontAwesomeIcon
    },
    created() {
        window.addEventListener('resize', this.handleLayoutChange);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleLayoutChange);
    },
    data(){
        return{
            aspectRatio: window.innerWidth / window.innerHeight,

            headImageUrl: require('@/assets/Head.jpeg'),
            name: "Zhihao Huang",
            jobTitle: "Undergraduate, Software Engineering",
            worksFor: "South China University of Technology",
            githubLink: "https://github.com/kellehod",
            blogLink: "https://blog.hzh2022.love",
            bilibiliLink: "https://space.bilibili.com/495384985",
            
            PersonalProfile: "Hello everyone, I am a personal trainee who has been practicing for two and a half years. I enjoy singing and dancing Rap basketball.",
            educationList: [
                {
                    course: "Bachelor's Degree Student, Software Engineering, 2021-2025",
                    institution: "South China University of Technology"
                },
            ],
        }
    },
    methods: {
        scrollTo() {
        this.$scrollTo(this.$el, 500); // 滑动到当前组件
        },
        handleLayoutChange() {
            const aspectRatio = window.innerWidth / window.innerHeight;

            if (aspectRatio <= 1.2) {
                document.querySelector('.profile-container').classList.add('vertical-layout');
            } else {
                document.querySelector('.profile-container').classList.remove('vertical-layout');
            }
        }
    },
    };
    
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
.home {
  text-align: center;
  padding: 35px;
}

.profile-container {
  display: flex;
  flex-direction: row; /* 默认为水平排列 */
  justify-content: center;
  align-items: center;
}

.vertical-layout {
  flex-direction: column; /* 垂直排列 */
}

.profile-left {
  /* 样式设置 */

}

.profile-left img{
    max-width:100%;
    width:200px;
    height:200px;
    border-radius:50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.portrait-title h2 {
    font-size: 1.75em;
    font-weight: 300;
    font-family:'Exo', sans-serif;
    color: #000000;
    margin: 20px 0 10px 0;
}

.portrait-title h3 {
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Exo', sans-serif;
    color: rgba(0,0,0, 0.54);
    margin: 0px 0 10px 0;
}
.network-icon{
    list-style-type: none; /* 移除列表项前面的小圆点 */
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:x-large;
    color:#007bff;
    padding:0;
}

.network-icon li{
    margin: 0 12px; /* 调整图标之间的间距 */
    transition: transform 0.2s; /* 添加过渡效果 */
}

.network-icon a {
  text-decoration: none; /* 去除下划线 */
  color: #007bff; /* 设置图标的默认颜色 */
}

.network-icon li:hover{
    transform: scale(1.3); /* 悬停时放大 */
}

.profile-right {
  /* 样式设置 */
  padding:20px;
  max-width:600px;
}

.margin-left{
    margin-left: 30px;
}

.profile-right h2{
    text-align:left;
    font-family: 'Open Sans', sans-serif;
    color:rgba(0,0,0, 0.9);
}

.profile-right p {
    color: rgba(0,0,0, 0.8);
    font-family: 'Noto Sans', sans-serif;
    text-align: left;
}

.education h3{
    text-align:left;
    margin-top:50px;
    font-family: 'Open Sans', sans-serif;
    color:rgba(0,0,0, 0.9);
}

.education{
    font-family: 'Noto Sans', sans-serif;
}

.education ul{
    list-style-type: none; /* 移除列表项前面的小圆点 */
}

.education li{
    display:flex;
}

.education .graduation-cap{
    margin-top:20px;
}

.description{
    margin-left:10px;
}

.description .course{
    font-family: 'Noto Sans', sans-serif;
}

.description .institution{
    font-family: 'Noto Sans', sans-serif;
    color:rgba(0,0,0, 0.54);
    font-size:small;
}
</style>
