<template>
  <div class="about">
    <h2>About Me</h2>
    <p>I am a passionate developer who loves creating web applications using Vue.js and other modern technologies.</p>
    <p v-for="item in names">{{ item }}</p>
  </div>
</template>

<script>
export default {
  name: 'About',
  data(){
        return{
            names:["boa","qwe","boa","boa","boa","boa","boa","boa","boa","boa","boa","boa","boa","boa","boa","boa","boa",]
        }
    },
  methods: {
        scrollTo() {
            this.$scrollTo(this.$el, 500); // 滑动到当前组件
        },
    },
};
</script>

<style scoped>
.about {
  text-align: center;
  padding: 35px;
}
</style>
