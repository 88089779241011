import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker'
import router from './router'
import VueScrollTo from 'vue-scrollto';

// 添加 Google Fonts 链接
import './assets/google-fonts.css';


createApp(App).use(VueScrollTo).mount('#app')
