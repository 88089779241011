<template>
<div class="card-container">
    <div class="card-overlay">
    <div class="card">
        <img :src="cardData.imageUrl" alt="Card Image" class="card-image">
        <div class="descri">
            <h3>{{ cardData.title }}</h3>
            <p>{{ cardData.description }}</p>
        </div>
    </div>
    <div class="btn">
        <button @click="hideCard" class="close-button" style="color: white;"><font-awesome-icon :icon="['fas', 'xmark']" /></button>
    </div>
    </div>
</div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// 添加 Solid 图标到图标库
library.add(fas);

export default {
name:"Card",
components:{
    FontAwesomeIcon
},
props: {
    cardData: {
      type: Object,
      required: true
    }
},
data() {
    return {
    
    };
},
methods: {
    hideCard() {
        this.$emit('closeCard'); // 触发事件，通知父组件关闭卡片
    }
}
};
</script>

<style>
.card-container {
text-align: center;
padding: 10px;
}

.show-card-button {
background-color: #3498db;
color: white;
border: none;
padding: 10px 20px;
cursor: pointer;
}

.card-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7);
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}

.card {
background-color: white;
padding: 20px;
border-radius: 5px;
box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
text-align: center;
width: 60%;
height: 50%;
max-width: 680px;
overflow: auto; /* 启用内容溢出滚动 */
}

.card-image {
  max-width: 100%; /* 图片最大宽度为卡片宽度 */
  max-height: 200px; /* 图片最大高度 */
  margin-bottom: 10px;
}

.btn{
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.btn button{
    background: none;
    border: none;
    cursor: pointer;
    font-size: x-large;
}


</style>
  